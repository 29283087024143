@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
    --primary: #4361ee;
    --secondary: #e62d74;
    --teritary: #F91EFF;
    --red: #e63946;
    --yellow: #f9c74f;
    --green: #2a9d8f;
    --orange: #f4a261;
    --purple: #9d4edd;
} */

:root {
    --primary: #27BDBE;
    --secondary: #F26522;
    --teritary: #F91EFF;
    --red: #e63946;
    --yellow: #f9c74f;
    --green: #2a9d8f;
    --orange: #f4a261;
    --purple: #9d4edd;
}


/* Cursor */
.cursor-pointer {
    cursor: pointer;
}

/* Margins */
.m-all {
    margin: 10px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-40 {
    margin-right: 40px;
}

.mh-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mh-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mh-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mh-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mv-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mv-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mv-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mv-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}


/* Padding */
.p-all {
    padding: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.ph-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.ph-30 {
    padding-left: 30px;
    padding-right: 30px;
}


.ph-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pv-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pv-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pv-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pv-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

/* Gap */

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}

.gap-40 {
    gap: 40px;
}

/* Width */
.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-97 {
    width: 97%;
}



/* Text */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.b {
    font-weight: bold;
}

.b-200 {
    font-weight: 200;
}

.b-300 {
    font-weight: 300;
}

.b-400 {
    font-weight: 400;
}

.b-500 {
    font-weight: 500;
}

.b-600 {
    font-weight: 600;
}

.b-700 {
    font-weight: 700;
}

.b-800 {
    font-weight: 800;
}

.b-900 {
    font-weight: 900;
}

.row {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
}

.wrap-row {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.justifyCenter {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.items-start {
    align-items: flex-start;
}

.alignEnd {
    align-items: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.gap {
    gap: 10px;
}

/* Paddings */
.paddingAll {
    padding: 10px;
}


/* Margins */
.mt-4 {
    margin-top: 10px;
}

.mt-4 {
    margin-top: 20px;
}

.mb-4 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

/* Gaps */
.gap-4 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

/* Text */
.headerText {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}


.a-link {
    font-weight: 300;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

/* header */
.header {
    position: sticky;
    top: 0;
    width: 100%;
    left: 0;
    background-color: white;
}


/* container */
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: "scroll";
    width: "100%";
}

.reg-container {
    padding: 20px;
}

.inner-container {
    max-width: 1000px;
    width: 100%;
    height: 100%;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    width: 100%;
}

.input-container-horizontal {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
}

.button-cta {
    padding: 8px 16px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #aab4bf;
    background: none;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button-cta:hover {
    background-color: #aab4bf;
    color: white;
}

.button-delete {
    padding: 8px 16px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #e62d74;
    background: none;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #e62d74;
}

.button-delete:hover {
    background-color: #e62d74;
    color: white;
}

.button-cta-lesson {
    padding: 8px 16px;
    border-radius: 20px;
    outline: none;
    border: 1px solid var(--primary);
    background: none;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: var(--primary);
}

.button-cta-lesson:hover {
    background-color: var(--primary);
    color: white;
}

.right {
    justify-content: right;
}

.left {
    justify-content: left;
}


/* Button */
.button {
    padding: 8px 16px;
    border-radius: 5px;
    outline: none;
    font-weight: 500;
    font-size: 12px;
    transition: all 0.2s ease-in-out;
    background-color: var(--primary);
    color: #ffffff;
    cursor: pointer;
    border: none;
}

.button:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}


/* Disabled Button */
.button:disabled {
    background-color: #aab4bf;
    color: white;
    cursor: not-allowed;
}

/* .button-cta {
    padding: 5px 15px;
    border-radius: 3px;
    outline: none;
    border: 1px solid #aab4bf;
    background: none;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button-cta:hover {
    background-color: #aab4bf;
    color: white;
} */

/* Input */

.input-label {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}

.text-input {
    padding: 10px;
    /* border: 0.5px solid var(--teritary); */
    border: 1px solid lightgrey;
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    transition: all 0.2s ease-in-out;
}

/* disable */
.text-input:disabled {
    background-color: #f5f7f9;
    color: black;
    cursor: not-allowed;
}


.checkbox-input {
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    margin: 0;
    padding: 0;
}

.select-input {
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    appearance: none;
}

.textarea-input {
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
    transition: all 0.2s ease-in-out;
    resize: none;
    min-height: 100px;
}

.text-input:hover,
.textarea-input:hover {
    border: 1px solid var(--primary);
}

.backgroundLess {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
}

/*  */

.link {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    text-decoration: none;
}


/* navbar */
.navbar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--secondary);
    align-items: flex-start;
    justify-content: left;
    width: 300px;
}

.navbarItem {
    cursor: pointer;
    padding: 10px;
    /* border-radius: 20px; */
    width: 100%;
}

.navbarItemIcon {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    width: 100%;
}

.navbarItem:hover {
    /* background-color: var(--teritary); */
}

.navbar-menu {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    transition: all 0.1s ease-in-out;
    border-radius: 3px;
}

.component {
    background-color: #f5f7f9;
    border-bottom: 1px solid #cfd7df;
    padding: 8px 16px;
    position: sticky;
    width: 100%;
}

.component-title {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.card {
    background-color: white;
    border-bottom: 1px solid #cfd7df;
    padding: 16px;
    width: 100%;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}

.card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}

/* modal */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100dvw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    min-width: 500px;
    background-color: white;
    /* border-radius: 10px; */
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1001;
}

.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modal-title {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.modal-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.blockbuilder-branding {
    display: none;
}

.header-link {
    text-decoration: none;
    color: #000000;
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    gap: 5px;
}


/* File */
.file-input-container {
    transition: all 0.2s ease-in-out;
    width: 100%;
    background-color: white;
    padding: 20px 40px;
    border-radius: 20;
    border-style: dashed;
    border: 1px dashed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-input-container.hover {
    border: 2px solid var(--primary);
    background-color: #f5f7f9;
}


.hide-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    /* IE 10+ */
    & {
        -ms-overflow-style: none;
    }

    /* Chrome  */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Safari and Chrome */
    &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
    }
}

button {
    font-family: 'Poppins', sans-serif;
}

progress[value]::-webkit-progress-value {
    background-image:
        -webkit-linear-gradient(-45deg,
            transparent 33%, rgba(0, 0, 0, .1) 33%,
            rgba(0, 0, 0, .1) 66%, transparent 66%),
        -webkit-linear-gradient(top,
            rgba(255, 255, 255, .25),
            rgba(0, 0, 0, .25)),
        -webkit-linear-gradient(left, #09c, #f44);

    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}
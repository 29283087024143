.profile-hover :hover {
    background-color: #f1f1f1;
}

.coy-website:hover {
    color: #000;
    text-decoration: underline;
}

.hover-card:hover{
    box-shadow: 1px 4px 8px 2px rgba(0,0,0,0.2);
    border: 0.5px solid #f1f1f1;
}

.hover-underline:hover {
    text-decoration: underline;
}